import auth from "./auth"
import me from "./me"
import * as cars from "./cars"
import * as rentals from "./rentals"
import offers from "./offers"
import * as utils from "./utils"
import * as calculations from "./calculations"
import * as money from "./money"
import * as coupon from "./coupon"

export default {
  auth,
  me,
  utils,
  calculations,
  money,
  cars,
  rentals,
  offers,
  coupon
}
