import HyrecarApi from "./index"

export const calculateEarnings = (rental: {
  extensions?: Array<{ rent_payable: number }>
  rent_payable: number
}) => {
  const extensions = rental.extensions || []
  const earningsFromExtensions = extensions.reduce((earnings, extension) => {
    return earnings + extension.rent_payable
  }, 0)
  return ((rental.rent_payable + earningsFromExtensions) /
    HyrecarApi.money.CENTS_PER_DOLLAR).toFixed(2)
}
