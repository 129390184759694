import { makeCallback, HotEnum, LeadSourceEnum, UserTypeEnum } from "./utils"

export const tokenSecretKey = (secret_key, callback) => ({
  url: `/token/${secret_key}`,
  ...makeCallback(callback)
})

const login = (
  input: { email: string; password: string; type?: UserTypeEnum },
  callback: Function
) => {
  return {
    url: `/login`,
    method: "POST",
    body: JSON.stringify({
      email: input.email,
      password: input.password,
      type: typeof input.type !== "undefined" ? input.type : undefined
    }),
    ...makeCallback(callback),
    then: res => {
      if (res.errors) {
        const newInput = {
          ...input,
          type:
            input.type === (UserTypeEnum.DRIVER as Number)
              ? UserTypeEnum.PARTNER
              : UserTypeEnum.DRIVER
        }
        return login(newInput, callback)
      }
      if (res.data && res.data.secret) {
        return tokenSecretKey(res.data.secret, callback)
      }
      return callback(null, res.data)
    }
  }
}

const registerUser = (
  input: {
    type: UserTypeEnum
    email: string
    password: string
    first_n: string
    last_n: string
    phone: string
    zip: string
    tos: boolean
    hot: HotEnum
    photo?: File
    lead_source?: LeadSourceEnum
    querystring?: string
  },
  callback: Function
) => {
  const formData = new FormData()

  for (let key of Object.keys(input)) {
    if (key !== "photo") {
      formData.append(key, input[key])
    }
  }

  if (input.photo) {
    formData.append("filename", input.photo, "filename.jpg")
  }

  return {
    url: `/register`,
    method: "POST",
    headers: { "Content-Type": null },
    body: formData,
    ...makeCallback(callback),
    then: res =>
      login({ email: input.email, password: input.password }, callback)
  }
}

export default {
  login,
  registerUser,
  tokenSecretKey
}
