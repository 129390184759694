export const SERVER_DATE_FORMAT = "YYYY-MM-DD HH:mm:ss"
export const makeCallback = (callback?: Function) => ({
  then: callback
    ? result => {
        return callback(undefined, result.data)
      }
    : undefined,
  catch: callback
    ? err => {
        return callback(err, undefined)
      }
    : undefined
})

export const uploadPhoto = (url: string, photo: Blob, callback: Function) => {
  const formData = new FormData()
  formData.append("filename", photo)
  return {
    url: url,
    method: "POST",
    headers: { "Content-Type": null },
    body: formData,
    ...makeCallback(callback)
  }
}

export const makeDeferredCallback = (deferred: any) => (err, res) => {
  if (err) {
    return deferred.reject(err)
  } else {
    return deferred.resolve(res)
  }
}

export enum UserTypeEnum {
  DRIVER = 0,
  PARTNER = 1
}

export enum LeadSourceEnum {
  "Web Site",
  "Google Adwords",
  "Facebook",
  "carshareleads",
  "RSD Referrals (list)",
  "RideShareGuys"
}

export enum HotEnum {
  ASAP = "ASAP",
  SOON = "Very Soon",
  LOOKING = "Just Looking"
}

export enum TimeDenominationEnum {
  MONTH = "month",
  WEEK = "week",
  DAY = "day"
}
