import {
  getMonthsWeeksAndDays,
  getDiscountAmount,
  getDiscountPercentage
} from "./calculations"
import { TimeDenominationEnum } from "./utils"
export const CENTS_PER_DOLLAR = 100
export const INSURANCE_CENTS_PER_DAY = 1000
export const TRANSACTION_FEE = 0.1
export const DEBIT_CARD_DEPOSIT_CENTS = 20000
// priceUnlockCents: 3900
// priceMVRCents: 2999,

export const getPriceBreakdown = (info: {
  rentalDays: number
  dailyPriceInCents: number
  weeklyPriceInCents: number
  monthlyPriceInCents: number
  fundingType?: "credit" | "debit"
  coupon?: {
    is_dollar: number
    discount: number
  }
}) => {
  const {
    rentalDays,
    dailyPriceInCents,
    weeklyPriceInCents,
    monthlyPriceInCents,
    fundingType = "credit",
    coupon
  } = info

  const { months, weeks, days } = getMonthsWeeksAndDays(rentalDays)
  const basePriceBeforeDiscounts = dailyPriceInCents * rentalDays
  const monthlyDiscount =
    months *
    getDiscountAmount(
      dailyPriceInCents,
      monthlyPriceInCents,
      TimeDenominationEnum.MONTH
    )
  const monthlyDiscountPercent = Math.round(
    getDiscountPercentage(
      dailyPriceInCents,
      monthlyPriceInCents,
      TimeDenominationEnum.MONTH
    ) * 100
  )
  const weeklyDiscount =
    weeks *
    getDiscountAmount(
      dailyPriceInCents,
      weeklyPriceInCents,
      TimeDenominationEnum.WEEK
    )
  const weeklyDiscountPercent = Math.round(
    getDiscountPercentage(
      dailyPriceInCents,
      weeklyPriceInCents,
      TimeDenominationEnum.WEEK
    ) * 100
  )

  const basePriceAfterLongRentalDiscounts =
    months * monthlyPriceInCents +
    weeks * weeklyPriceInCents +
    days * dailyPriceInCents

  const transactionFee = Math.round(
    basePriceAfterLongRentalDiscounts * TRANSACTION_FEE
  )
  const insuranceFee = Math.round(rentalDays * INSURANCE_CENTS_PER_DAY)

  const couponDiscount =
    coupon && coupon.is_dollar ? coupon.discount * CENTS_PER_DOLLAR : 0

  const grandTotal =
    basePriceAfterLongRentalDiscounts +
    transactionFee +
    insuranceFee -
    couponDiscount
  return {
    basePriceBeforeDiscounts,
    weeklyDiscount,
    weeklyDiscountPercent,
    monthlyDiscount,
    monthlyDiscountPercent,
    basePriceAfterLongRentalDiscounts,
    transactionFee,
    insuranceFee,
    couponDiscount,
    grandTotal,
    refundableDeposit: fundingType === "debit" ? DEBIT_CARD_DEPOSIT_CENTS : 0
  }
}
