import moment from "moment"
import { makeCallback, makeDeferredCallback } from "./utils"
import auth from "./auth"

const BASE_URL = "/me/"

const getMe = callback => ({
  url: BASE_URL,
  ...makeCallback(callback)
})
const uploadDriversLicense = (license: Blob, callback) => {
  const formData = new FormData()
  formData.append("type", "license")
  formData.append("filename", license)
  return {
    url: "/photo/screenshot",
    method: "POST",
    headers: { "Content-Type": null },
    body: formData,
    ...makeCallback(callback)
  }
}
const uploadProfilePhoto = (photo: Blob, callback) => {
  const formData = new FormData()
  formData.append("filename", photo)
  return {
    url: "/photo/user",
    method: "POST",
    headers: { "Content-Type": null },
    body: formData,
    ...makeCallback(callback)
  }
}

const updateMe = (
  newUserProfile: {
    license_photo?: Blob
    profile_photo?: Blob
    data_basic?: object
    data_type_specified?: object
    tnc_accepted?: Number
  },
  callback: Function
) => {
  const when = require("when")
  const callbacks = {
    updateMeCallback: when.defer(),
    licensePhoto: when.defer(),
    profilePhoto: when.defer()
  }
  const promises: Array<any> = []
  promises.push(callbacks.updateMeCallback.promise)
  if (newUserProfile.license_photo) {
    promises.push(callbacks.licensePhoto.promise)
  }
  if (newUserProfile.profile_photo) {
    promises.push(callbacks.profilePhoto.promise)
  }
  when
    .all(promises)
    .then(res => {
      callback(undefined, res)
    })
    .catch(err => {
      callback(err)
    })

  let andThen = {
    license_photo: newUserProfile.license_photo
      ? uploadDriversLicense(
          newUserProfile.license_photo,
          makeDeferredCallback(callbacks.licensePhoto)
        )
      : undefined,
    profile_photo: newUserProfile.profile_photo
      ? uploadProfilePhoto(
          newUserProfile.profile_photo,
          makeDeferredCallback(callbacks.profilePhoto)
        )
      : undefined
  }
  for (let key of Object.keys(andThen)) {
    if (!andThen[key]) {
      delete andThen[key]
    }
  }
  return {
    url: BASE_URL,
    method: "PUT",
    body: JSON.stringify({
      data_basic: newUserProfile.data_basic,
      data_type_specified: newUserProfile.data_type_specified,
      tnc_accepted: newUserProfile.tnc_accepted
    }),
    ...makeCallback(makeDeferredCallback(callbacks.updateMeCallback)),
    andThen: updatedMe => andThen
  }
}

const submitPaymentAndStartBackgroundCheck = (
  input: {
    stripeToken: {
      token_id: String
      last4: Number | String
      funding: String
      brand: String
    }
    ssn: String
    coupon?: String
  },
  callback: Function
) => ({
  submit_payment_and_start_background_check: {
    url: "/me/stripe_tos",
    method: "PUT",
    body: JSON.stringify({
      stripe_tos_accepted: 1
    }),
    then: () => ({
      url: "/me/stripe_customer",
      method: "PUT",
      body: JSON.stringify({
        ...input.stripeToken
      }),
      then: () => ({
        url: "/me/mvr_check",
        method: "POST",
        body: JSON.stringify({
          ssn: input.ssn,
          coupon: input.coupon
        }),
        then: () => ({
          url: `/me/mvr_check`,
          method: "PUT",
          ...makeCallback(callback)
        }),
        catch: callback
      }),
      catch: callback
    }),
    catch: callback
  }
})

const updateAchInfo = (
  input: {
    token_id: string
    last4: string | number
    bank_name: string
    business_name?: string
    business_tax_id?: string | number
    first_n: string
    last_n: string
    zip: string | number
    address: string
    city: string
    state: string
    dob: Date
    confirm_account_number: string | number
    ssn_last4: string | number
  },
  callback: Function
) => ({
  update_ach: {
    url: "/me/stripe_tos",
    method: "PUT",
    body: JSON.stringify({
      stripe_tos_accepted: 1
    }),
    then: () => ({
      url: "/me/stripe_account",
      method: "PUT",
      body: JSON.stringify({
        ...input,
        dob: moment(input.dob).format("YYYY-MM-DD 00:00:00")
      }),
      then: res => callback(null, res),
      catch: callback
    }),
    catch: callback
  }
})

export default {
  me: BASE_URL,
  getMe,
  updateMe,
  uploadProfilePhoto,
  uploadDriversLicense,
  submitPaymentAndStartBackgroundCheck,
  updateAchInfo,
  switchAccount: callback => {
    return {
      url: `${BASE_URL}switch_account`,
      method: "PUT",
      ...makeCallback(callback),
      then: res => auth.tokenSecretKey(res.data.secret, callback)
    }
  }
}
