import React from "react"
import PropTypes from "prop-types"
import invariant from "invariant"

export default class HyrecarProvider extends React.Component {
  static propTypes = {
    client: PropTypes.any.isRequired,
    children: PropTypes.element.isRequired
  }

  static childContextTypes = {
    restClient: PropTypes.any.isRequired
  }
  public props: {
    client: any
    children: any
  }
  constructor(
    props: {
      client: any
    },
    context: object
  ) {
    super(props, context)

    invariant(
      props.client,
      "HyrecarApi was not passed a client instance. Make " +
        'sure you pass in your client via the "client" prop.'
    )
  }

  getChildContext() {
    return {
      restClient: this.props.client
    }
  }

  render() {
    return React.Children.only(this.props.children)
  }
}
