import moment from "moment"
import {
  makeCallback,
  makeDeferredCallback,
  uploadPhoto,
  SERVER_DATE_FORMAT
} from "./utils"

export enum CarRentalStatus {
  WAITING_INSURANCE = "PENDING_INSURANCE", // 5,
  CONFIRM_PICKUP = "PENDING_PICKUP", // 6,
  DRIVER_HAS_CAR = "ACTIVE" //0
}

export enum CarStatus {
  PENDING_APPROVAL,
  PENDING_VERIFICATION,
  FAILED_VERIFICATION,
  CAR_ON_MARKET,
  POTENTIAL_DRIVERS,
  WAITING_OWNER,
  WAITING_INSURANCE,
  CONFIRM_PICKUP,
  DRIVER_HAS_CAR,
  LATE_RENTAL,
  CANCELLED_BY_OWNER,
  CANCELLED_BY_DRIVER,
  ARCHIVED
}

export enum DriverAction {
  MESSAGE_OWNER,
  GET_DIRECTIONS,
  EXTEND_RENTAL
}

export enum OwnerAction {
  MESSAGE_DRIVER,
  EDIT_CAR,
  SEE_APPLICANTS,
  CONFIRM_PICKUP,
  CONFIRM_DROPOFF,
  CONTACT_SUPPORT,
  ASK_FOR_EXTENSION,
  RELIST
}

const getCarOwnerStatus = (car, owner) => {
  if (car.verified === 2) {
    return CarStatus.FAILED_VERIFICATION
  }
  if ((owner && owner.verified === 0) || car.verified === 0) {
    return CarStatus.PENDING_VERIFICATION
  }
  if (car.applications_cnt) {
    return CarStatus.POTENTIAL_DRIVERS
  }
  if (car.rental) {
    const rentalStatus = car.rental.status
    if (rentalStatus === CarRentalStatus.WAITING_INSURANCE) {
      return CarStatus.WAITING_INSURANCE
    }
    if (rentalStatus === CarRentalStatus.CONFIRM_PICKUP) {
      return CarStatus.CONFIRM_PICKUP
    }
    if (rentalStatus === CarRentalStatus.DRIVER_HAS_CAR) {
      if (
        moment(car.rental.confirmed_pickup_dt, SERVER_DATE_FORMAT)
          .add(car.rental.days, "days")
          .toDate() < new Date()
      ) {
        return CarStatus.LATE_RENTAL
      }
      return CarStatus.DRIVER_HAS_CAR
    }
  }
  if (car.status === 1 && car.verified === 1) {
    return CarStatus.CAR_ON_MARKET
  }
  if (car.status === 0 && car.verified === 1) {
    return CarStatus.ARCHIVED
  }
  return CarStatus.PENDING_APPROVAL
}

const getCarDriverStatus = application => {
  if (application.rental_id === 0) {
    return CarStatus.WAITING_OWNER
  }
  if (application.rental_status === CarRentalStatus.WAITING_INSURANCE) {
    return CarStatus.WAITING_INSURANCE
  }
  if (application.rental_status === CarRentalStatus.CONFIRM_PICKUP) {
    return CarStatus.CONFIRM_PICKUP
  }
  if (application.rental_status === CarRentalStatus.DRIVER_HAS_CAR) {
    if (
      moment(application.confirmed_pickup_dt, SERVER_DATE_FORMAT)
        .add(application.rental_days, "days")
        .toDate() < new Date()
    ) {
      return CarStatus.LATE_RENTAL
    }
    return CarStatus.DRIVER_HAS_CAR
  }
  return CarStatus.WAITING_OWNER
}

const uploadCarPhoto = (
  input: {
    carId: string | number
    photo: Blob
  },
  callback?: Function
) => {
  const formData = new FormData()
  formData.append("filename", input.photo)
  return {
    url: `/photo/car/${input.carId}`,
    method: "POST",
    headers: { "Content-Type": null },
    body: formData,
    ...makeCallback(callback)
  }
}

const uploadCarRegistration = (
  input: {
    carId: string | number
    file: Blob
  },
  callback?: Function
) => {
  const formData = new FormData()
  formData.append(
    "filename",
    input.file,
    input.file.type.indexOf("image") !== -1 ? "filename.jpg" : "filename.pdf"
  )
  return {
    url: `/photo/car/${input.carId}/registration`,
    method: "POST",
    headers: { "Content-Type": null },
    body: formData,
    ...makeCallback(callback)
  }
}

const uploadLyftInspection = (
  input: {
    carId: string | number
    file: Blob
  },
  callback?: Function
) => {
  const formData = new FormData()
  formData.append(
    "filename",
    input.file,
    input.file.type.indexOf("image") !== -1 ? "filename.jpg" : "filename.pdf"
  )
  return {
    url: `/photo/car/${input.carId}/uber_lyft_inpsection`,
    method: "POST",
    headers: { "Content-Type": null },
    body: formData,
    ...makeCallback(callback)
  }
}

const uploadPersonalInsurance = (
  input: {
    carId: string | number
    file: Blob
  },
  callback?: Function
) => {
  const formData = new FormData()
  formData.append(
    "filename",
    input.file,
    input.file.type.indexOf("image") !== -1 ? "filename.jpg" : "filename.pdf"
  )
  return {
    url: `/photo/car/${input.carId}/personal_insurance`,
    method: "POST",
    headers: { "Content-Type": null },
    body: formData,
    ...makeCallback(callback)
  }
}

const createCar = (
  input: {
    city: string
    description: string
    h_no?: string
    licensing?: string
    mark_id: number
    ml_day: number // miles per day
    model_id: number
    p2p_19_point_inspection?: number // 1
    p2p_price_daily: number
    p2p_price_monthly: number
    p2p_price_weekly: number
    pickup_date?: Date // "0000-00-00 00:00:00"
    pickup_lat: number
    pickup_lng: number
    plate: string
    registration_expiration_date: Date // "2017-08-31 00:00:00"
    state: string // "CA"
    street: string
    vin: string
    year_id: number
    zip: string
    photos: Array<Blob>
    registration: Blob
    uber_lyft_inpsection: Blob
    personal_insurance?: Blob
  },
  callback: Function
) => {
  const when = require("when")
  const callbacks = {
    listingCallback: when.defer(),
    photosCallbacks: input.photos.map(() => when.defer()),
    registrationCallback: when.defer(),
    lyftInspectionCallback: when.defer(),
    personalInsuranceCallback: when.defer()
  }
  const operations = [
    callbacks.listingCallback.promise,
    ...callbacks.photosCallbacks.map(photoCb => photoCb.promise),
    callbacks.registrationCallback.promise,
    callbacks.lyftInspectionCallback.promise
  ]
  if (input.personal_insurance) {
    operations.push(callbacks.personalInsuranceCallback.promise)
  }
  when
    .all(operations)
    .then(res => {
      callback(undefined, res)
    })
    .catch(err => {
      callback(err)
    })
  return {
    url: "/cars",
    method: "POST",
    body: JSON.stringify({
      city: input.city,
      description: input.description,
      h_no: input.h_no || "",
      licensing: input.licensing || "personal",
      mark_id: input.mark_id,
      ml_day: input.ml_day, // miles per day
      model_id: input.model_id,
      p2p_19_point_inspection: input.p2p_19_point_inspection || 1, // 1
      p2p_price_daily: input.p2p_price_daily,
      p2p_price_monthly: input.p2p_price_monthly,
      p2p_price_weekly: input.p2p_price_weekly,
      pickup_date: input.pickup_date || new Date("0000-00-00 00:00:00"), // "0000-00-00 00:00:00"
      pickup_lat: input.pickup_lat || 0,
      pickup_lng: input.pickup_lng || 0,
      plate: input.plate,
      registration_expiration_date: moment(
        input.registration_expiration_date
      ).format("YYYY-MM-DD h:mm:ss"), // "2017-08-31 00:00:00"
      state: input.state, // "CA"
      street: input.street,
      vin: input.vin,
      year_id: input.year_id,
      zip: input.zip
    }),
    ...makeCallback(makeDeferredCallback(callbacks.listingCallback)),
    andThen: createdCar => {
      const followingOperations = {
        ...input.photos
          .map((p, i) =>
            uploadCarPhoto(
              { carId: createdCar.data.id, photo: p },
              makeDeferredCallback(callbacks.photosCallbacks[i])
            )
          )
          .reduce((photos: object, photo: object, i: number) => {
            const newPhotos = { ...photos }
            newPhotos[i] = photo
            return newPhotos
          }, {}),
        registration: uploadCarRegistration(
          {
            carId: createdCar.data.id,
            file: input.registration
          },
          makeDeferredCallback(callbacks.registrationCallback)
        ),
        lyftInspection: uploadLyftInspection(
          {
            carId: createdCar.data.id,
            file: input.uber_lyft_inpsection
          },
          makeDeferredCallback(callbacks.lyftInspectionCallback)
        )
      }
      if (input.personal_insurance) {
        followingOperations["personalInsurance"] = uploadPersonalInsurance(
          {
            carId: createdCar.data.id,
            file: input.personal_insurance
          },
          makeDeferredCallback(callbacks.personalInsuranceCallback)
        )
      }
      return followingOperations
    }
  }
}

const updateCar = (
  input: {
    carId: number
    city: string
    description: string
    h_no?: string
    licensing?: string
    mark_id: number
    ml_day: number // miles per day
    model_id: number
    p2p_19_point_inspection?: number // 1
    p2p_price_daily: number
    p2p_price_monthly: number
    p2p_price_weekly: number
    pickup_date?: Date // "0000-00-00 00:00:00"
    pickup_lat: number
    pickup_lng: number
    plate: string
    registration_expiration_date: Date // "2017-08-31 00:00:00"
    state: string // "CA"
    street: string
    vin: string
    year_id: number
    zip: string
    registration?: Blob
    uber_lyft_inpsection?: Blob
  },
  callback: Function
) => {
  const when = require("when")
  const callbacks = {
    listingCallback: when.defer(),
    registrationCallback: when.defer(),
    lyftInspectionCallback: when.defer()
  }
  const promises: Array<any> = [callbacks.listingCallback.promise]
  const andThen: { registration?: Object; lyftInspection?: Object } = {}

  if (input.registration) {
    promises.push(callbacks.registrationCallback.promise)
    andThen.registration = uploadCarRegistration(
      {
        carId: input.carId,
        file: input.registration
      },
      makeDeferredCallback(callbacks.registrationCallback)
    )
  }
  if (input.uber_lyft_inpsection) {
    promises.push(callbacks.lyftInspectionCallback.promise)
    andThen.lyftInspection = uploadLyftInspection(
      {
        carId: input.carId,
        file: input.uber_lyft_inpsection
      },
      makeDeferredCallback(callbacks.lyftInspectionCallback)
    )
  }
  when
    .all(promises)
    .then(res => {
      callback(undefined, res)
    })
    .catch(err => {
      callback(err)
    })
  return {
    url: `/cars/${input.carId}`,
    method: "PUT",
    body: JSON.stringify({
      city: input.city,
      description: input.description,
      h_no: input.h_no || "",
      licensing: input.licensing || "personal",
      mark_id: input.mark_id,
      ml_day: input.ml_day, // miles per day
      model_id: input.model_id,
      p2p_19_point_inspection: input.p2p_19_point_inspection || 1, // 1
      p2p_price_daily: input.p2p_price_daily,
      p2p_price_monthly: input.p2p_price_monthly,
      p2p_price_weekly: input.p2p_price_weekly,
      pickup_date: input.pickup_date || new Date("0000-00-00 00:00:00"), // "0000-00-00 00:00:00"
      pickup_lat: input.pickup_lat,
      pickup_lng: input.pickup_lng,
      plate: input.plate,
      registration_expiration_date: moment(
        input.registration_expiration_date
      ).format("YYYY-MM-DD h:mm:ss"), // "2017-08-31 00:00:00"
      state: input.state, // "CA"
      street: input.street,
      vin: input.vin,
      year_id: input.year_id,
      zip: input.zip
    }),
    ...makeCallback(makeDeferredCallback(callbacks.listingCallback)),
    andThen: createdCar => andThen
  }
}

const confirmPickup = (
  input: {
    rentalId
    car_front_photo: Blob
    car_passenger_side_photo: Blob
    car_back_photo: Blob
    car_driver_side_photo: Blob
    car_mileage_gas_photo: Blob
    car_mileage_photo: Blob
  },
  callback: Function
) => {
  const when = require("when")
  const callbacks = {
    car_front_photo: when.defer(),
    car_passenger_side_photo: when.defer(),
    car_back_photo: when.defer(),
    car_driver_side_photo: when.defer(),
    car_mileage_gas_photo: when.defer(),
    car_mileage_photo: when.defer()
  }
  const promises: Array<any> = []
  promises.push(callbacks.car_back_photo.promise)
  promises.push(callbacks.car_driver_side_photo.promise)
  promises.push(callbacks.car_front_photo.promise)
  promises.push(callbacks.car_mileage_gas_photo.promise)
  promises.push(callbacks.car_mileage_photo.promise)
  promises.push(callbacks.car_passenger_side_photo.promise)
  when
    .all(promises)
    .then(res => {
      callback(undefined, res)
    })
    .catch(err => {
      callback(err)
    })

  let andThen = {
    car_front_photo: uploadPhoto(
      `/photo/rental/${input.rentalId}/car_front_photo`,
      input.car_front_photo,
      makeDeferredCallback(callbacks.car_front_photo)
    ),
    car_passenger_side_photo: uploadPhoto(
      `/photo/rental/${input.rentalId}/car_passenger_side_photo`,
      input.car_passenger_side_photo,
      makeDeferredCallback(callbacks.car_passenger_side_photo)
    ),
    car_back_photo: uploadPhoto(
      `/photo/rental/${input.rentalId}/car_back_photo`,
      input.car_back_photo,
      makeDeferredCallback(callbacks.car_back_photo)
    ),
    car_driver_side_photo: uploadPhoto(
      `/photo/rental/${input.rentalId}/car_driver_side_photo`,
      input.car_driver_side_photo,
      makeDeferredCallback(callbacks.car_driver_side_photo)
    ),
    car_mileage_gas_photo: uploadPhoto(
      `/photo/rental/${input.rentalId}/car_mileage_gas_photo`,
      input.car_mileage_gas_photo,
      makeDeferredCallback(callbacks.car_mileage_gas_photo)
    ),
    car_mileage_photo: uploadPhoto(
      `/photo/rental/${input.rentalId}/car_mileage_photo`,
      input.car_mileage_photo,
      makeDeferredCallback(callbacks.car_mileage_photo)
    )
  }
  for (let key of Object.keys(andThen)) {
    if (!andThen[key]) {
      delete andThen[key]
    }
  }
  return {
    url: "/cars",
    method: "GET",
    andThen: () => andThen
  }
}

export {
  getCarOwnerStatus,
  getCarDriverStatus,
  uploadCarPhoto,
  uploadLyftInspection,
  uploadCarRegistration,
  uploadPersonalInsurance,
  createCar,
  updateCar,
  confirmPickup
}
