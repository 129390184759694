import moment from "moment"
import { TimeDenominationEnum } from "./utils"
export const DAY = 1
export const DAYS_IN_WEEK = DAY * 7
export const DAYS_IN_MONTH = DAY * 30

export const getMonthsWeeksAndDays = (rentalDays: number) => {
  let days, months, weeks
  days = rentalDays

  months = Math.floor(days / DAYS_IN_MONTH)
  days = days - months * DAYS_IN_MONTH

  weeks = Math.floor(days / DAYS_IN_WEEK)
  days = days - weeks * DAYS_IN_WEEK

  return { months, weeks, days }
}

export const getDiscountPercentage = (
  dailyPrice: number,
  discountedPrice: number,
  type: TimeDenominationEnum
) => {
  let basePrice
  switch (type) {
    case TimeDenominationEnum.MONTH:
      basePrice = dailyPrice * DAYS_IN_MONTH
      break
    case TimeDenominationEnum.WEEK:
      basePrice = dailyPrice * DAYS_IN_WEEK
      break
    case TimeDenominationEnum.DAY:
      basePrice = dailyPrice * DAY
      break
  }
  return (basePrice - discountedPrice) / basePrice
}

export const getDiscountAmount = (
  dailyPrice: number,
  discountedPrice: number,
  type: TimeDenominationEnum
) => {
  let basePrice
  switch (type) {
    case TimeDenominationEnum.MONTH:
      basePrice = dailyPrice * DAYS_IN_MONTH
      break
    case TimeDenominationEnum.WEEK:
      basePrice = dailyPrice * DAYS_IN_WEEK
      break
    case TimeDenominationEnum.DAY:
      basePrice = dailyPrice * DAY
      break
  }
  return basePrice - discountedPrice
}

export const getPrice = (perDayPrice: number, type: TimeDenominationEnum) => {
  switch (type) {
    case TimeDenominationEnum.MONTH:
      return perDayPrice * DAYS_IN_MONTH
    case TimeDenominationEnum.WEEK:
      return perDayPrice * DAYS_IN_WEEK
    case TimeDenominationEnum.DAY:
      return perDayPrice * DAY
  }
}
