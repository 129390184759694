import { connect } from "react-refetch"
import HyrecarRequest from "./HyrecarRequest"

export const customFetch = (input: HyrecarRequest) => {
  return fetch(input.getUrl(), input.getOptions()).then(response => {
    return response
  })
}

export default (buildRequest: Function): void =>
  connect.defaults({
    buildRequest,
    fetch: customFetch
  })
