import moment from "moment"
import { makeCallback, SERVER_DATE_FORMAT } from "./utils"

const requestBooking = (
  input: {
    carId: number
    rentalDays: number
    startDate: Date
    coupon?: String
  },
  callback: Function
) => {
  return {
    url: `/offers/${input.carId}/applications`,
    method: "POST",
    body: JSON.stringify({
      pickup_timeslot_1: 10,
      pickup_timeslot_2: 14,
      pickup_timeslot_3: 19,
      wants_days_number: input.rentalDays,
      wants_end_dt: moment(input.startDate)
        .add(input.rentalDays, "days")
        .format(SERVER_DATE_FORMAT),
      wants_start_dt: moment(input.startDate).format(SERVER_DATE_FORMAT),
      coupon: input.coupon
    }),
    ...makeCallback(callback)
  }
}

export default {
  requestBooking
}
