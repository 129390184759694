class HyrecarRequest {
  url: string
  options: object
  constructor(newUrl, newOptions) {
    this.url = newUrl
    this.options = newOptions
  }
  getUrl() {
    return this.url
  }
  getOptions() {
    return this.options
  }
}

export default HyrecarRequest
